import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

// import { SITE_URL } from 'constants'
import slugify from 'helpers/slugify'

import s from './styles.module.css'

// todo: this needs to be improved.
const LinkWordPress = ({
  linkType,
  link,
  productLinkType,
  collectionHandle,
  productHandle,
  stockistTitle,
  linkLabel,
  noPageLink,
  fullyClickable,
  className,
}) => {
  const isMailOrTelLink = ['mailto:', 'tel:'].some((el) =>
    link?.url?.includes(el)
  )
  const trimmedLink = link?.url?.replace('https://chalkuk.com', '')
  const isFullLink =
    !noPageLink && (linkType === 'product' || linkType === 'stockist')
  const isExternalink =
    !isMailOrTelLink && linkType === 'page' && link.target !== ''
  const isInternalLink =
    !isMailOrTelLink && linkType === 'page' && link.target === ''
  const isStockistLink = linkType === 'stockist' && stockistTitle
  const decideProductLinkType = isStockistLink
    ? `/stockist/${slugify(stockistTitle)}`
    : (productLinkType && productLinkType === 'product') ||
      (noPageLink && linkType === 'product')
    ? `/product/${productHandle}`
    : `/collection/${collectionHandle}`
  const externalLinkProps = {
    target: '_blank',
    rel: 'noopenner noreferrer',
  }
  const setClassName = {
    className,
  }

  const decideLink = () => {
    if (isMailOrTelLink) return link.url
    if (isFullLink) return decideProductLinkType
    if (noPageLink) return decideProductLinkType
    if (isExternalink) return link.url
    if (isInternalLink) return trimmedLink
  }

  return (
    <>
      {fullyClickable &&
        (isExternalink ? (
          <a
            href={decideLink()}
            className={s.fullyClickable}
            {...externalLinkProps}
          />
        ) : (
          <Link to={decideLink()} className={s.fullyClickable} />
        ))}
      {isFullLink && (
        <Link to={decideLink()} {...setClassName}>
          {linkLabel}
        </Link>
      )}
      {noPageLink && (
        <Link to={decideLink()} {...setClassName}>
          {linkLabel}
        </Link>
      )}
      {(isMailOrTelLink || isExternalink) && (
        <a href={decideLink()} {...setClassName} {...externalLinkProps}>
          {link.title}
        </a>
      )}
      {isInternalLink && (
        <Link to={decideLink()} {...setClassName}>
          {link.title}
        </Link>
      )}
    </>
  )
}

LinkWordPress.propTypes = {
  linkType: PropTypes.string.isRequired,
  link: PropTypes.shape({
    target: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  productLinkType: PropTypes.string,
  collectionHandle: PropTypes.string,
  productHandle: PropTypes.string,
  stockistTitle: PropTypes.string,
  linkLabel: PropTypes.string,
  noPageLink: PropTypes.bool,
  fullyClickable: PropTypes.bool,
  className: PropTypes.string,
}
LinkWordPress.defaultProps = {
  linkType: '',
  link: {},
  productLinkType: '',
  collectionHandle: '',
  productHandle: '',
  stockistTitle: '',
  linkLabel: '',
  noPageLink: false,
  fullyClickable: false,
  className: '',
}

export default LinkWordPress
