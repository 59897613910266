import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import HeaderSimple from 'components/HeaderSimple'
import ProductItem from 'components/ProductItem'

import s from './styles.module.css'

const ProductionPartners = ({ data }) => {
  const {
    seo: { title: seoTitle, description: seoDescription },
    acfProductionPartnerPost: {
      productionPartnerPostHeader: header,
      // shopifyCollectionHandle,
    },
  } = data.wordpressProductionPartner

  return (
    <Layout>
      <Helmet>
        <html data-theme-color="ebb" />
      </Helmet>
      <SEO
        title={`${seoTitle.replace(
          '| Chalk UK',
          ''
        )} | Production Partners | Chalk UK`}
        description={seoDescription}
      />
      <HeaderSimple
        data={{
          introduction: header.title || null,
          description: header.description || null,
        }}
      >
        {data.wordpressProductionPartner.featuredImage && (
          <GatsbyImage
            className={s.img}
            image={getImage(
              data.wordpressProductionPartner.featuredImage.node.localFile
            )}
            alt=""
            backgroundColor="#eee"
          />
        )}
      </HeaderSimple>
      {data.allShopifyCollection.nodes[0]?.products && (
        <div className={s.products}>
          {data.allShopifyCollection.nodes[0].products.map((product) => (
            <Fragment key={product.title}>
              <ProductItem data={product} />
            </Fragment>
          ))}
        </div>
      )}
    </Layout>
  )
}

export default ProductionPartners

ProductionPartners.propTypes = {
  data: PropTypes.shape({
    wordpressProductionPartner: PropTypes.shape({
      seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      featuredImage: PropTypes.object,
      acfProductionPartnerPost: PropTypes.shape({
        productionPartnerPostHeader: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }),
        shopifyCollectionHandle: PropTypes.string,
      }),
    }),
    allShopifyCollection: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query ProductionPartnersByID($id: String!, $collectionHandle: String!) {
    wordpressProductionPartner(id: { eq: $id }) {
      seo {
        title
        description
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, placeholder: NONE)
            }
          }
        }
      }
      acfProductionPartnerPost {
        productionPartnerPostHeader {
          title
          description
        }
        shopifyCollectionHandle
      }
    }
    allShopifyCollection(filter: { handle: { eq: $collectionHandle } }) {
      nodes {
        products {
          handle
          title
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: NONE
                  sizes: "(max-width: 900px) 250px, (max-width: 1920px) 370px, (max-width: 6000px) 800px"
                  breakpoints: [250, 370, 640]
                  aspectRatio: 0.75
                )
              }
            }
          }
          metafields {
            value
            type
            key
          }
          variants {
            metafields {
              value
              type
              key
            }
          }
        }
      }
    }
  }
`
