import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import LinkWordPress from 'components/LinkWordPress'

import s from './styles.module.css'

const HeaderSimple = ({ data, children, className }) => {
  const {
    introduction,
    description,
    linkType,
    collectionHandle,
    productHandle,
    linkLabel,
  } = data || {}

  return (
    <div className={classNames(s.container, className)}>
      <div className={s.wrapper}>
        {introduction && (
          <h1
            className={s.title}
            dangerouslySetInnerHTML={{
              __html: introduction,
            }}
          />
        )}
        {description && (
          <div
            className={s.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        <LinkWordPress
          linkType={linkType}
          collectionHandle={collectionHandle}
          productHandle={productHandle}
          linkLabel={linkLabel}
          noPageLink
        />
        {children}
      </div>
    </div>
  )
}

HeaderSimple.propTypes = {
  data: PropTypes.shape({
    introduction: PropTypes.string,
    description: PropTypes.string,
    linkType: PropTypes.string,
    collectionHandle: PropTypes.string,
    productHandle: PropTypes.string,
    linkLabel: PropTypes.string,
  }),
  children: PropTypes.node,
  className: PropTypes.string,
}
HeaderSimple.defaultProps = {
  data: {},
  children: null,
  className: '',
}

export default HeaderSimple
